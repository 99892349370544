<template>
<div v-if="adReady" class="bg-white">
  <b-container>
    <b-row v-for="slide in ad.slides" :key="'slide-' + slide.id">
      <b-col>
        <h1>{{slide.title}}</h1>
        <markdown :content="slide.body" />
      </b-col>
    </b-row>
  </b-container>
  <b-container class="my-4">
    <b-row>
      <b-col>
        <h1>Controls</h1>
      </b-col>
    </b-row>
    <b-row v-for="control in controls" :key="'control-list-' + control.id" class="my-3">
      <b-col>
        <h4>{{control.name}}</h4>
        <p>{{control.description}}</p>
        <ul>
          <li v-for="attribute in control.attributes" :key="'control-'+ control.id + '-attr-' + attribute.id">
            <strong>{{attribute.name}}</strong> - {{attribute.description}}
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="my-4" v-if="ad.functions">
    <b-row>
      <b-col>
        <h1>Functional mapping</h1>
      </b-col>
    </b-row>
    <b-row v-for="fun in ad.functions" :key="'function-' + fun.id">
      <b-col class="mb-2">
        <h4>{{fun.name}}</h4>
        <ol v-if="fun.controls.length > 0">
          <li v-for="control in fun.controls" :key="'control-' + control.id">
            {{control.name}}
          </li>
        </ol>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="my-4" v-if="ad.cycles">
    <b-row>
      <b-col>
        <h1>Lifecycle mapping</h1>
      </b-col>
    </b-row>
    <b-row v-for="cycle in ad.cycles" :key="'cycle-' + cycle.id">
      <b-col class="mb-2">
        <h4>{{cycle.name}}</h4>
        <ol v-if="cycle.controls.length > 0">
          <li v-for="control in cycle.controls" :key="'control-' + control.id">
            {{control.name}}
          </li>
        </ol>
      </b-col>
    </b-row>
  </b-container>

  <b-container>
    <b-row class="py-5">
      <b-col>
        <h1>Typical project timeline</h1>
      </b-col>
    </b-row>
    <b-row class="my-4 py-4 bg-white">
      <b-col>
        <div id="visualization"></div>
      </b-col>
    </b-row>
    <b-row class="my-4" v-for="phase in ad.timeline" :key="'phase-' + phase.id">
      <b-col class="mb-4">
        <h4>{{phase.content}}</h4>
        <ol>
          <li v-for="activity in phase.activities" :key="getRandom(activity)">
            {{activity.content}}
          </li>
        </ol>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="py-5">
    <b-row>
      <b-col>
        <h1>Process diagram</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div id="mermaid" class="mermaid" v-html="ad.configuration.graph">
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>
<script>
import _ from 'lodash'
import { DataSet } from "vis-data/peer"
import { Timeline } from "vis-timeline/peer"
import "vis-timeline/styles/vis-timeline-graph2d.css"
import mermaid from 'mermaid'
import moment from 'moment'
import Markdown from '@/components/Markdown.vue'

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
    Markdown
  },
  computed: {
    ad: function () {
      return this.$store.state.appData
    },
    adReady: function () {
      if (this.ad.configuration) {
        return true
      }
      return false
    },
    controls: function () {
      return _.sortBy(this.ad.controls, ['name'])
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "UT Export"
    this.$stat.log({ page: 'UT app start', action: 'open UT app' })
  },
  mounted: function () {
    this.timelineShow()
    this.draw()
  },
  data () {
    return {
      timeline: null
    }
  },
  methods: {
    draw: async function () {
      await delay(50)
      mermaid.initialize({ theme: 'default', startOnLoad: false, securityLevel: 'loose' })
      const container = document.getElementById('mermaid')
      mermaid.init(this.ad.configuration.graph, container)
    },
    getRandom: function () {
      const min = 1
      const max = 9999999999999
      return Math.floor(Math.random() * (max - min) + min)
    },
    timelineShow: function () {
      if (this.timeline) {
        this.timeline.destroy()
      }
      this.showTimelineFlag = true
      // DOM element where the Timeline will be attached
      const container = document.getElementById("visualization")

      // Create a DataSet (allows two way data-binding)
      const leadTime = this.ad.timelineConfiguration.start
      const groupsData = this.ad.timelineGroups
      const timelineData = _.map(this.ad.timeline, x => {
        const item = {
          id: x.id,
          content: x.content,
          start: moment().add(leadTime + x.start, 'days'),
          group: x.group
        }
        if (x.end) {
          item.end = moment().add(leadTime + x.end, 'days')
        }
        return item
      })
      const items = new DataSet(timelineData)
      const groups = new DataSet(groupsData)

      // Configuration for the Timeline
      const options = {
        start: moment().subtract(7, 'days'),
        end: moment().add(3, 'months')
      }

      // Create a Timeline
      this.timeline = new Timeline(container, items, groups, options)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ h1 {
  margin-top: 4rem;
}

/deep/ h2 {
  margin-top: 3rem;
}

/deep/ h3 {
  margin-top: 2rem;
}

/deep/ h4 {
  margin-top: 1.5rem;
}
</style>
